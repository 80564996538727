import React from 'react'
import history from '../../util/history'
import foot_phone from '../../assets/img/foot-phone.png'
import './index.scss'

export default class FloatDialog extends React.Component{
  jumpUrl (url) {
    history.push('/' + url)
  }
  render() {
    return (
      <div className="phoneDialog">
        <div className="float-phone" onClick={this.jumpUrl.bind(this, 'pengaduan')}>
          <img src={foot_phone} alt=""></img>
          <div>Klik di sini untuk</div>
          <span>Layanan Pengaduan</span>
        </div>
      </div>
    )
  }
}