import React from 'react'
import home_bg1 from '../../assets/img/index/home_bg1.png'
import home_bg2 from '../../assets/img/index/home_bg2.png'
import banner1 from '../../assets/img/index/banner1.png'
import banner2 from '../../assets/img/index/banner2.png'
import banner3 from '../../assets/img/index/banner3.png'
import welfare1 from '../../assets/img/index/welfare1.png'
import welfare2 from '../../assets/img/index/welfare2.png'
import welfare3 from '../../assets/img/index/welfare3.png'
import './index.scss'

export default class home extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      list: [
        {
          icon: banner1,
          title: 'Dapatkan Pinjaman Usaha',
          detail: `Sebagai pemilik usaha, Anda juga dapat mengajukan pinjaman usaha
          setelah perusahaan Anda menggunakan system penggajian dari
          Modana. Perluas usaha Anda sekarang juga!`
        }, {
          icon: banner2,
          title: 'Cashflow Terjaga',
          detail: `Karyawan tidak perlu melakukan kasbon ke perusahaan, karena
          mendapatkan akses pinjaman dari Mopinjam. Jaga kodisi keuangan
          perusahaan Anda sekaligus tingkatkan loyalitas karyawan Anda.`
        }, {
          icon: banner3,
          title: 'Kelola Bisnis Lebih Mudah',
          detail: `Mopinjam terintegrasi dengan sistem penggajian Modana. Bebaskan diri Anda
          dari khawatir. Gunakan sistem informasi manajemen karyawan dan penggajian
          dari Modana.
          Gratis!`
        }
      ],
      activeList: 0,
      welfareList: [
        {
          icon: welfare1,
          detail: 'Ajukan pinjaman dimanapun dan kapanpun sesuai kebutuhan dan urgensi Anda. ',
        }, {
          icon: welfare2,
          detail: 'Proses penilaian kelayakan kredit cepat, karena data Anda sudah tercatat pada sistem informasi manajemen karyawan Modana yang telah bekerja sama dengan Mopinjam. Tidak perlu repot submit banyak dokumen.',
        }, {
          icon: welfare3,
          detail: 'Jangan khawatir pinjaman melebihi kemampuan. Sistem kami secara otomatis menghitung jumlah pinjaman yang sesuai dengan kemampuan Anda.',
        },
      ],
      activeWelfare: 0,
    }
  }

  addActive (active) {
    if (active === 2) {
      active = 0
    } else {
      active++
    }
    this.setState({
      activeList: active
    })
  }

  reduceActive (active) {
    if (active === 0) {
      active = 2
    } else {
      active--
    }
    this.setState({
      activeList: active
    })
  }

  changeActive (active) {
    this.setState({
      activeWelfare: active
    })
  }

  toHref(url) {
    window.location.href = url
  }

  render() {
    const {
      list,
      activeList,
      welfareList,
      activeWelfare,
    } = this.state
    return (
      <div className="home">
        <div className="home-top">
          <img src={home_bg1} alt=""></img>
          <div>
            <p>Pinjaman Mudah</p>
            <p>untuk Kebutuhan</p>
            <p>Karyawan Anda</p>
            <div onClick={() => {
              this.toHref('https://modana.id/')
            }}>Lebih Lanjut</div>
          </div>
          <img src={home_bg2} alt="" />
        </div>

        <div className="home-banner">
          <h4>Aneka Ragam Keuntungan untuk Anda, Pemilik Usaha</h4>
          <p>Mopinjam adalah platform pinjaman untuk karyawan Anda dengan fasilitas debit gaji otomatis melalui sistem penggajian karyawan Modana.id</p>
          <ul>
            {list.map((item, index) => {
              return activeList === index ? (<li key={index}>
                <img src={item.icon} alt="" />
                <div>
                  <span className="left" onClick={this.reduceActive.bind(this, index)}></span>
                  <div>
                    <div>
                      <p>{item.title}</p>
                      <span>{item.detail}</span>
                    </div>
                    <div onClick={() => {
                      this.toHref('https://hr.modana.id/signup')
                    }}>Daftar Modana - Gratis</div>
                  </div>
                  <span className="right" onClick={this.addActive.bind(this, index)}></span>
                </div>
              </li>) : (
                ''
              )
            })}
          </ul>
        </div>

        <div className="bom">
          <p>Anda Karyawan?</p>
          <p>Banyak Keuntungan Menanti</p>
          <ul>
            {welfareList.map((item, index) => {
              return <li key={index} className={index === activeWelfare ? 'active' : ''}>
                <img src={item.icon} onClick={this.changeActive.bind(this, index)} alt="" />
                <div>{item.detail}</div>
              </li>
            })}
          </ul>
          <div>
            <p>Daftarkan perusahaan Anda di aplikasi Mopinjam.</p>
            <p>Dapatkan hadiah menarik setelah perusahaan yang Anda referensikan</p>
            <p>menggunakan sistem penggajian Modana.</p>
            <p className="last">Masukkan informasi perusahaan tempat Anda bekerja sekarang.</p>
            <div className="btn" onClick={() => {
              this.toHref('https://modana.id/')
            }}>Referensukan</div>
            <div className="bubble">Beritahu Bos Anda!</div>
          </div>
        </div>
      </div>
    )
  }
}