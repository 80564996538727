import React from 'react';
import history from '../../util/history';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import logo from "../../assets/img/logo.png";
import id from "../../assets/img/id.png";
import './index.scss'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  }
}));

export default function ButtonAppBar(props) {
  const classes = useStyles();
  const jumpUrl = (url) => {
    history.push('/' + url)
  }

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className="appBar">
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            <img src={logo} alt="" onClick={() => { jumpUrl('home') }}></img>
          </Typography>
          <span onClick={() => { jumpUrl('product') }}>Produk</span>
          <span onClick={() => { jumpUrl('faq') }}>FAQ</span>
          <span onClick={() => { jumpUrl('about') }}>Tentang Kami</span>
          <span onClick={() => { jumpUrl('lender') }}>Pemberi Pinjanan</span>
          <span onClick={() => { jumpUrl('conditions') }}>Syarat dan Ketentuan</span>
          <img src={id} alt=""></img>
          <small>ID</small>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </div>
  );
}