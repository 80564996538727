import React, { useState } from 'react'
import floatIcon from '../../assets/img/floatIcon.png'
import './index.scss'

const FloatDialog = () => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true)
  }
  
  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div className="floatDialog">
      <div className="floatIcon" onClick={handleClickOpen}>
        {/* <img src={floatIcon} alt="" /> */}
        <div>TKB90 100%</div>
      </div>
      {open ? (<div
        className="float-dialog-con"
        onClick={handleClose}>
        <div className="float-dialog-dom">
          <div className="close"></div>
          <div className="title">Tingkat Keberhasilan</div>
          <div className="txt1">TKB90 = 100% - NPL90</div>
          <div className="txt2">NPL90 ditentukan menggunakan rumus perhitungan:</div>
          <div className="txt3">
            <span>NPL90 = </span>
            <div>
              outstanding kredit macet di atas 90 hari
              <hr/>
              outstanding keseluruhan
            </div>
            <span>x100%</span>
          </div>
          <div className="txt4">NPL90 = (NPL after 90 days/total outstanding)x100%</div>
        </div>
      </div>) : (
        ''
      )}
    </div>
  );
}

export default FloatDialog
