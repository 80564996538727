import React from "react";
import loadable from '../util/loadable.js'
import history from '../util/history'
import { Router, Route, Redirect, Switch } from 'react-router-dom'
import home from '../view/home'
import ButtonAppBar from '../compontent/AppBar/index'
import Footer from '../compontent/footer/index'
import FloatDialog from '../compontent/floatDialog/index'
import FloatPhone from '../compontent/floatPhone/index'

const routes = [
  {
    path: "/home",
    component: home
  }, {
    path: "/product",
    component: loadable(() => import ('../view/product/index.js'))
  }, {
    path: "/faq",
    component: loadable(() => import ('../view/faq/index.js'))
  }, {
    path: "/about",
    component: loadable(() => import ('../view/about/index.js'))
  }, {
    path: "/lender",
    component: loadable(() => import ('../view/lender/index.js'))
  }, {
    path: "/contactUs",
    component: loadable(() => import ('../view/contactUs/index.js'))
  }, {
    path: "/Terms",
    component: loadable(() => import ('../view/Terms/index.js'))
  }, {
    path: "/PrivacyPolicy",
    component: loadable(() => import ('../view/PrivacyPolicy/index.js'))
  }, {
    path: "/pengaduan",
    component: loadable(() => import ('../view/pengaduan/index.js'))
  }, {
    path: "/conditions",
    component: loadable(() => import ('../view/conditions/index.js'))
  }
];

function routers() {
  return (
    <Router history={history}>
      <React.Suspense fallback>
        {/* 导航 */}
        <ButtonAppBar />

        <Switch>
          {routes.map((route, index) => {
            return <Route key={index} path={route.path} component={route.component}/>
          })}
          <Redirect path="/" to="/home" />
        </Switch>
        
        <FloatDialog />
        <FloatPhone />
        <Footer />
      </React.Suspense>
    </Router>
  );
}

export default routers;